import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { getDKTToken } from '../redux/actions/SSO'
import { getUser } from '../redux/actions/user'
import { LOCAL_STORAGE_KEYS } from '@/utils/constants'
import { refreshToken } from '@/redux/services/user'

type Tprops = {
  children?: any
  getDKTToken: any
  user: any
  getUser: any
}

const mapStateToProps = (state) => ({
  user: state.user,
})

const mapDispatchToProps = {
  getDKTToken: getDKTToken,
  getUser: getUser,
}

const SSO = ({ children, getDKTToken, user, getUser }: Tprops) => {
  const router = useRouter()
  const isFunnel = ['/information', '/shipping', '/payment'].includes(
    router.pathname
  )
  const currentLocale = router.locale

  useEffect(() => {
    if (
      router &&
      router?.query &&
      router?.query?.code &&
      !router?.query?.product &&
      localStorage?.getItem(LOCAL_STORAGE_KEYS.TOKEN) !== 'null'
    ) {
      const code = router?.query?.code
      const redirectUrl = isFunnel
        ? currentLocale === 'fr'
          ? `${process.env.SSO_REDIRECT_URL_ON_FUNNEL}`
          : `${process.env.SSO_REDIRECT_URL}de/shipping`
        : currentLocale === 'fr'
        ? `${process.env.SSO_REDIRECT_URL}`
        : `${process.env.SSO_REDIRECT_URL}de`
      const dktLogin = async () => {
        // get the token and save it as auth token
        const token = await getDKTToken(code, redirectUrl)
        if (token.type === 'GET_TOKEN_SUCCESS') {
          localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN)
          localStorage.setItem(
            LOCAL_STORAGE_KEYS.CONNEXION_TYPE,
            process.env.SSO_TYPE
          )
          localStorage.setItem(
            LOCAL_STORAGE_KEYS.TOKEN,
            token?.token?.access_token
          )
          localStorage.setItem(LOCAL_STORAGE_KEYS.USER_TYPE, 'SSO')
          await getUser()
          // add redirection to form page to check and add all needed values
        }
      }
      dktLogin()
    }

    if (router) {
      const tokenExpireAt = localStorage?.getItem(
        LOCAL_STORAGE_KEYS.TOKEN_EXPIRE_AT
      )
      const connexionType = localStorage?.getItem(
        LOCAL_STORAGE_KEYS.CONNEXION_TYPE
      )

      // only for CIAM - Virgin
      if (tokenExpireAt && connexionType === 'ciam') {
        const refresh = async () => {
          return await refreshToken()
        }
        const currentDate = Math.floor(new Date().getTime() / 1000)
        if (Number(tokenExpireAt) < currentDate) {
          refresh().then((res) => {
            localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, res.access_token)
            localStorage.setItem(
              LOCAL_STORAGE_KEYS.REFRESH_TOKEN,
              res.refresh_token
            )
            localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, res.expires_in)
          })
        }
      }
    }
  }, [])

  useEffect(() => {
    if (
      typeof user?.user !== 'undefined' &&
      user?.user !== '' &&
      router.pathname !== '/account/informations-check'
    ) {
      const requiredFields = [
        'firstName',
        'lastName',
        'phoneNumber',
        'gender',
      ].filter((field) => {
        const value = user?.user?.[field]
        return typeof value !== 'string' || value.trim() === ''
      })

      if (requiredFields.length > 0 && user.isLoggedIn) {
        router.push('/account/informations-check')
      }
    }
  }, [user?.user])

  return children
}

export default connect(mapStateToProps, mapDispatchToProps)(SSO)
